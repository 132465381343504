
.live-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .el-scrollbar {
        margin: 20px;
        height: calc(100% - 40px);
    }

    ::v-deep .el-scrollbar__wrap {
        height: 100%;
        overflow-x: hidden;

        .el-scrollbar__view {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    .live-title {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
    }
}

.live-breadcrumb {
    ::v-deep.el-breadcrumb__item {

        &:last-child .el-breadcrumb__inner {
            color: #ABABBB;
        }

        .el-breadcrumb__inner {
            color: #5C5C6F;
        }

        .el-breadcrumb__separator {
            color: #5C5C6F;
        }
    }
}

.steps-wrapper {
    margin: 30px 0 0;

    ::v-deep .step-item {

        .step-title {
            color: #fff;
            opacity: 0.2;
        }

        .step-line {
            left: -140px
        }

        .step-text {
            background: #262963;
            opacity: 0.5;
        }

        &.current {
            .step-text {
                background: #2821FC;
                opacity: 1;
            }

            .step-title {
                opacity: 1;
            }
        }
    }
}

.form-content {
    /*flex: 1;*/
    /*height: 1%;*/
    width: 100%;
    margin-top: 20px;
    box-sizing: content-box;
}

.footer-content {
    text-align: center;
    margin: 20px 0;

    .fade-btn {
        background: #0C0E3F;
        border: 1px solid #262963;
        color: #fff;
    }
}
